import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import QuestionBankCategory from "../QuestionBank/QuestionBankCategory.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import Loader from "../../../../MainComponents/Loader.jsx";
import AddEditChooseQuestion from "../QuestionBank/AddEditChooseQuestion.jsx";

function AdminQuiz({ homework, exam, placement, type }) {
  const { examID, courseID } = useParams();
  const [refetch, setRefetch] = useState(false);

  //!--------- get quiz / homework info for checking if there is a quiz or a homework has set previously  -------
  let InfoApi = placement
    ? `${process.env.REACT_APP_ADMIN_GET_PLACEMENT_TEST_API}/${courseID}`
    : `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_ALL_INFO_API}${examID}`;

  const [getInfoSuccess, getInfoErrors, getInfoLoading] = useAxios(
    InfoApi,
    "GET",
    "GET",
    refetch
  );

  const staticNumber = getInfoSuccess?.n_section;
  // Create an array of the desired length
  const allSections = Array.from(
    { length: staticNumber },
    (_, index) => index + 1
  );

  console.log(placement, staticNumber, "jjj");

  if (getInfoLoading) {
    return <Loader />;
  }

  return (
    <section className="flex h-auto w-full flex-col items-start gap-20 overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Tests | Dr.Tamer Ghonim`}></HelmetTags>

      <div className="custom-question  w-full ">
        <h2 className=" text-3xl font-bold mb-6">Add a Question </h2>

        {exam || placement ? (
          <>
            <div className="flex items-center gap-6 justify-center">
              {allSections?.map((section, index) => (
                <Link
                  to={`${index + 1}`}
                  className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
                >
                  Section {index + 1}
                </Link>
              ))}
            </div>
          </>
        ) : null}

        <AddEditChooseQuestion
          type={"quiz"}
          id={getInfoSuccess?.data?.quiz_info[0]?.key}
          refetch={refetch}
          setRefetch={setRefetch}
          questionType={type}
          placement={placement}
        />

        {!exam && !placement ? (
          <>
            {getInfoSuccess?.data?.Questions?.length > 0 && (
              <div className="all-custom-questions w-full ">
                <h2 className=" text-3xl font-bold mb-8"> All Questions </h2>
                <div className="flex gap-4">
                  {getInfoSuccess?.data?.paragraph && (
                    <div className=" flex-1  bg-white rounded-2xl h-[90vh] overflow-y-auto ">
                      <div
                        className=" px-4"
                        dangerouslySetInnerHTML={{
                          __html: getInfoSuccess?.data?.paragraph,
                        }}
                      />
                    </div>
                  )}

                  <div className=" flex-1  bg-white rounded-2xl h-[90vh] overflow-y-auto">
                    <QuestionBankCategory
                      type={homework ? "homework" : "quiz"}
                      id={
                        homework
                          ? getInfoSuccess?.data?.homework_info[0].key
                          : getInfoSuccess?.data?.quiz_info[0]?.key
                      }
                      QuizQuestionsData={getInfoSuccess?.data?.Questions}
                      setRefetchQuiz={setRefetch}
                      refetchQuiz={refetch}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    </section>
  );
}

export default AdminQuiz;
