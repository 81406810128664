/* eslint-disable import/first */
import { Suspense, lazy } from "react";
const LazyLandingPage = lazy(() => import("./Pages/LandingPage/LandingPage"));
// eslint-disable-next-line import/first
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./SASS/signIn.scss";
import "./SASS/styles.scss";

import { Layout, Loader, NotFound } from "./MainComponents";
import {
  SignIn,
  Register,
  StudentLectures,
  StudentLecture,
  Exams,
  SingleExamPage,
  StudentQuestionBank,
  QuizNew,
  StudentShowQuestionBank,
  Profile,
  Amount,
  Invitations,
  StudentSession,
  StudentMaterials,
  StudentMaterialsView,
  StudentMaterialsViewReadingOrWriting,
  StudentMaterialsPracticesView,
  StudentMaterialsPdfsView,
  StudentMaterialsPracticesQuizzesView,
  HomePage,
  StudentInsghts,
} from "./Pages/Student";
import {
  AdminLayout,
  Students,
  EditStudent,
  AdminExamsQuizzes,
  Requests,
  Assistants,
  AddEditAssistant,
  ViewStudent,
  AdminProfile,
  AdminAddEditExamAndQuizzes,
  AdminViewExam,
  AdminQuiz,
  AdminQuizSection,
  AdminCourses,
  AdminAddEditCourse,
  AdminCourseDetails,
  AdminAddEditSession,
  AdminAddStudentToCourse,
  AdminCourseSession,
  AdminAddEditMatrialSession,
  // AdminMaterials,
  // AdminAddEditMaterial,
  // AdminMatrialPractises,
  // AdminAddEditMatrialExamAndQuiz,
  // AdminViewMatrials,
  // AdminMatrialsType,
  // AdminMaterialsPdf,
  // AdminMatrialPractisesView,
  // AdminMatrialPractisesQuizzesView,
  // Posts,
  // AddEditPost,
  AdminAddEditExamQuiz,
  AdminSkills,
  AdminAddEditSkills,
  Reviews,
  AdminPlacementTest,
  AdminDomains,
} from "./Pages/Admin";

import ProtectedAdminRoutes from "./MainComponents/ProtectionComponents/ProtectedAdminRoutes";
import { useAuthState } from "./MainComponents/GlobalContext.jsx";
import ModalAnswer from "./Pages/Student/Lectures/modalAnswer/ModalAnswer";
import AdminModalAnswer from "./Pages/Admin/Dashboard/ExamsQuizzes/AdminModalAnswer.jsx";
import ForgetPassword from "./Pages/Student/SignIn/ForgetPassword.jsx";
import StudentMaterialsStarter from "./Pages/Student/StudentMaterials/StudentMaterialsStarter.jsx";
import AdminMaterialsStarter from "./Pages/Admin/Dashboard/AdminMaterials/AdminMatrialsStarter.jsx";
import AddEditChooseQuestion from "./Pages/Admin/Dashboard/QuestionBank/AddEditChooseQuestion.jsx";

function App() {
  const AuthState = useAuthState();

  const router = createBrowserRouter([
    //!--------Landing Page Layout--------

    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/home/courses"
          isAllowed={!AuthState.userData?.student_token}
        >
          {/* <LandingLayout /> */}
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Loader />}>
              <LazyLandingPage />
            </Suspense>
          ),
        },
      ],
    },
    //!Student Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/signin"
          isAllowed={AuthState.userData?.student_token}
        >
          <Layout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/home/profile",
          element: <Profile />,
        },

        {
          path: "/home/insights",
          element: <StudentInsghts />,
        },
        {
          path: "/home/courses",
          element: <StudentLectures />,
        },
        {
          path: "/home/courses/sessions/:courseID",
          element: <StudentSession />,
        },
        {
          path: "/home/courses/sessions/:courseID/:sessionID",
          element: <StudentLecture />,
        },
        {
          path: "/home/courses/sessions/modal-answer/:lectureID",
          element: <ModalAnswer />,
        },
        {
          path: "/home/courses/sessions/solve-exam/:ID",
          element: <SingleExamPage />,
        },

        {
          path: "/home/exams/solve-placement-test/:ID",
          element: <QuizNew placement />,
        },
        {
          path: "/home/exams/modal-answer-placement/:ID",
          element: <ModalAnswer placement />,
        },

        // my courses
        {
          path: "/home/my-courses",
          element: <StudentLectures myCourses />,
        },
        {
          path: "/home/my-courses/sessions/:courseID",
          element: <StudentSession myCourses />,
        },
        {
          path: "/home/my-courses/sessions/:courseID/:sessionID",
          element: <StudentLecture myCourses />,
        },
        {
          path: "/home/my-courses/sessions/modalAnswer/:lectureID",
          element: <ModalAnswer myCourses />,
        },
        {
          path: "/home/my-courses/sessions/solve-exam/:ID",
          element: <SingleExamPage myCourses />,
        },

        {
          path: "home/exams",
          element: <Exams />,
        },
        {
          path: "/home/exams/:examID",
          element: <SingleExamPage />,
        },
        {
          path: "/home/exams/solve-test/:ID",
          element: <QuizNew exam />,
        },
        {
          path: "/home/exams/modal-answer/:ID",
          element: <ModalAnswer exam />,
        },
        //!Quiz
        {
          path: "/home/quiz/:ID",
          element: <QuizNew />,
        },
        //!Homework
        {
          path: "/home/homework/:ID",
          element: <QuizNew homework />,
        },
        //! Student Question Bank
        {
          path: "/home/question-bank",
          element: <StudentQuestionBank />,
        },
        {
          path: "/home/question-bank/:ID",
          element: <StudentShowQuestionBank />,
        },

        //! STUDENT MATRIALS
        //! est
        {
          path: "/home/est-materials",
          element: <StudentMaterialsStarter section="est" />,
        },
        {
          path: "/home/est-materials/past-paper",
          element: (
            <StudentMaterials section="est" pdfs pdfsType="past-paper" />
          ),
        },
        {
          path: "/home/est-materials/matrials",
          element: <StudentMaterialsView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading",
          element: <StudentMaterialsViewReadingOrWriting section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing",
          element: <StudentMaterialsViewReadingOrWriting section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/videos",
          element: <StudentMaterials section="est" reading videos />,
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials section="est" reading pdfs pdfsType="tests" />
          ),
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials section="est" reading pdfs pdfsType="books" />
          ),
        },
        {
          path: "/home/est-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials section="est" reading pdfs pdfsType="topics" />
          ),
        },

        {
          path: "/home/est-materials/matrials/writing/videos",
          element: <StudentMaterials section="est" videos />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs/tests",
          element: <StudentMaterials section="est" pdfs pdfsType="tests" />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs/books",
          element: <StudentMaterials section="est" pdfs pdfsType="books" />,
        },
        {
          path: "/home/est-materials/matrials/writing/pdfs/topics",
          element: <StudentMaterials section="est" pdfs pdfsType="topics" />,
        },

        {
          path: "/home/est-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials section="est" reading practicesType="exams" />
          ),
        },
        {
          path: "/home/est-materials/matrials/writing/practices/exams",
          element: <StudentMaterials section="est" practicesType="exams" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/writing/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="est" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials section="est" reading practicesType="topics" />
          ),
        },
        {
          path: "/home/est-materials/matrials/writing/practices/quizzes/topics",
          element: <StudentMaterials section="est" practicesType="topics" />,
        },
        {
          path: "/home/est-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials section="est" reading practicesType="drills" />
          ),
        },
        {
          path: "/home/est-materials/matrials/writing/practices/quizzes/drills",
          element: <StudentMaterials section="est" practicesType="drills" />,
        },

        //! -------------------------------------------
        //! act
        {
          path: "/home/act-materials",
          element: <StudentMaterialsStarter section="act" />,
        },
        {
          path: "/home/act-materials/past-paper",
          element: (
            <StudentMaterials section="act" pdfs pdfsType="past-paper" />
          ),
        },
        {
          path: "/home/act-materials/matrials",
          element: <StudentMaterialsView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading",
          element: <StudentMaterialsViewReadingOrWriting section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing",
          element: <StudentMaterialsViewReadingOrWriting section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/videos",
          element: <StudentMaterials section="act" reading videos />,
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials section="act" reading pdfs pdfsType="tests" />
          ),
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials section="act" reading pdfs pdfsType="books" />
          ),
        },
        {
          path: "/home/act-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials section="act" reading pdfs pdfsType="topics" />
          ),
        },

        {
          path: "/home/act-materials/matrials/writing/videos",
          element: <StudentMaterials section="act" videos />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs/tests",
          element: <StudentMaterials section="act" pdfs pdfsType="tests" />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs/books",
          element: <StudentMaterials section="act" pdfs pdfsType="books" />,
        },
        {
          path: "/home/act-materials/matrials/writing/pdfs/topics",
          element: <StudentMaterials section="act" pdfs pdfsType="topics" />,
        },

        {
          path: "/home/act-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials section="act" reading practicesType="exams" />
          ),
        },
        {
          path: "/home/act-materials/matrials/writing/practices/exams",
          element: <StudentMaterials section="act" practicesType="exams" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/writing/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="act" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials section="act" reading practicesType="topics" />
          ),
        },
        {
          path: "/home/act-materials/matrials/writing/practices/quizzes/topics",
          element: <StudentMaterials section="act" practicesType="topics" />,
        },
        {
          path: "/home/act-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials section="act" reading practicesType="drills" />
          ),
        },
        {
          path: "/home/act-materials/matrials/writing/practices/quizzes/drills",
          element: <StudentMaterials section="act" practicesType="drills" />,
        },
        //! sat
        {
          path: "/home/sat-materials",
          element: <StudentMaterialsStarter section="sat" />,
        },
        {
          path: "/home/sat-materials/past-paper",
          element: (
            <StudentMaterials section="sat" pdfs pdfsType="past-paper" />
          ),
        },
        {
          path: "/home/sat-materials/matrials",
          element: <StudentMaterialsView section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/reading",
          element: <StudentMaterialsViewReadingOrWriting section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/writing",
          element: <StudentMaterialsViewReadingOrWriting section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/reading/videos",
          element: <StudentMaterials section="sat" reading videos />,
        },
        {
          path: "/home/sat-materials/matrials/reading/pdfs",
          element: <StudentMaterialsPdfsView section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/reading/pdfs/tests",
          element: (
            <StudentMaterials section="sat" reading pdfs pdfsType="tests" />
          ),
        },
        {
          path: "/home/sat-materials/matrials/reading/pdfs/books",
          element: (
            <StudentMaterials section="sat" reading pdfs pdfsType="books" />
          ),
        },
        {
          path: "/home/sat-materials/matrials/reading/pdfs/topics",
          element: (
            <StudentMaterials section="sat" reading pdfs pdfsType="topics" />
          ),
        },

        {
          path: "/home/sat-materials/matrials/writing/videos",
          element: <StudentMaterials section="sat" videos />,
        },
        {
          path: "/home/sat-materials/matrials/writing/pdfs",
          element: <StudentMaterialsPdfsView section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/writing/pdfs/tests",
          element: <StudentMaterials section="sat" pdfs pdfsType="tests" />,
        },
        {
          path: "/home/sat-materials/matrials/writing/pdfs/books",
          element: <StudentMaterials section="sat" pdfs pdfsType="books" />,
        },
        {
          path: "/home/sat-materials/matrials/writing/pdfs/topics",
          element: <StudentMaterials section="sat" pdfs pdfsType="topics" />,
        },

        {
          path: "/home/sat-materials/matrials/reading/practices",
          element: <StudentMaterialsPracticesView section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/writing/practices",
          element: <StudentMaterialsPracticesView section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/reading/practices/exams",
          element: (
            <StudentMaterials section="sat" reading practicesType="exams" />
          ),
        },
        {
          path: "/home/sat-materials/matrials/writing/practices/exams",
          element: <StudentMaterials section="sat" practicesType="exams" />,
        },
        {
          path: "/home/sat-materials/matrials/reading/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/writing/practices/quizzes",
          element: <StudentMaterialsPracticesQuizzesView section="sat" />,
        },
        {
          path: "/home/sat-materials/matrials/reading/practices/quizzes/topics",
          element: (
            <StudentMaterials section="sat" reading practicesType="topics" />
          ),
        },
        {
          path: "/home/sat-materials/matrials/writing/practices/quizzes/topics",
          element: <StudentMaterials section="sat" practicesType="topics" />,
        },
        {
          path: "/home/sat-materials/matrials/reading/practices/quizzes/drills",
          element: (
            <StudentMaterials section="sat" reading practicesType="drills" />
          ),
        },
        {
          path: "/home/sat-materials/matrials/writing/practices/quizzes/drills",
          element: <StudentMaterials section="est" practicesType="drills" />,
        },

        {
          path: "/home/est-materials/practise/:ID",
          element: <SingleExamPage />,
        },
        {
          path: "/home/act-materials/practise/:ID",
          element: <SingleExamPage />,
        },
        {
          path: "/home/sat-materials/practise/:ID",
          element: <SingleExamPage />,
        },
      ],
    },
    //!Admin Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/admin/not-found"
          isAllowed={
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
          }
        >
          <AdminLayout />
        </ProtectedAdminRoutes>
      ),
      children: [
        //!------ students Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("students")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students",
              element: <Students />,
            },
            {
              path: "/admin/students/edit-student/:studentID",
              element: <EditStudent />,
            },
            {
              path: "/admin/students/view-student/:studentID",
              element: <ViewStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/requests",
              element: <Requests />,
            },
          ],
        },
        //! courses
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("courses")
                )
              }
            />
          ),
          children: [
            // {
            //   path: "/admin/courses",
            //   element: <AdminCourses />,
            // },
            // {
            //   path: "/admin/courses/:courseID",
            //   element: <AdminCourseDetails />,
            // },
            // {
            //   path: "/admin/courses/add-course",
            //   element: <AdminAddEditCourse />,
            // },
            // {
            //   path: "/admin/courses/edit-course/:courseID",
            //   element: <AdminAddEditCourse edit />,
            // },
            // {
            //   path: "/admin/courses/all-students/:courseID",
            //   element: <AdminViewExam addStudent />,
            // },
            // {
            //   path: "/admin/courses/all-students/:courseID/add-student",
            //   element: <AdminAddStudentToCourse />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID",
            //   element: <AdminCourseSession />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/add-video",
            //   element: <AdminAddEditMatrialSession type="video" />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/add-pdf",
            //   element: <AdminAddEditMatrialSession type="pdf" />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/add-link",
            //   element: <AdminAddEditMatrialSession type="link" />,
            // },

            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/add-exam",
            //   element: <AdminAddEditExamQuiz isExam />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/add-quiz",
            //   element: <AdminAddEditExamQuiz />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/exam/:examID",
            //   element: <AdminQuiz exam />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/quiz/:examID",
            //   element: <AdminQuiz />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/exam/:examID/:sectionNumber",
            //   element: <AdminQuizSection exam />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/quiz/:examID/:sectionNumber",
            //   element: <AdminQuizSection />,
            // },
            {
              path: "/admin/courses/:courseID/session/:sessionID/:examID",
              element: <AdminViewExam />,
            },

            {
              path: "/admin/courses/:courseID/session/:sessionID/edit-video",
              element: <AdminAddEditMatrialSession type="video" edit />,
            },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/edit-pdf",
            //   element: <AdminAddEditMatrialSession type="pdf" edit />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/edit-link",
            //   element: <AdminAddEditMatrialSession type="link" edit />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/edit-exam",
            //   element: <AdminAddEditExamQuiz isExam edit />,
            // },
            // {
            //   path: "/admin/courses/:courseID/session/:sessionID/edit-quiz",
            //   element: <AdminAddEditExamQuiz edit />,
            // },

            // {
            //   path: "/admin/courses/:courseID/add-session",
            //   element: <AdminAddEditSession />,
            // },
            // {
            //   path: "/admin/courses/:courseID/edit-session/:sessionID",
            //   element: <AdminAddEditSession edit />,
            // },
          ],
        },

        // EST MATRIALS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("est")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/est",
              element: <AdminCourses type="est" />,
            },
            {
              path: "/admin/est/:courseID",
              element: <AdminCourseDetails type="est" past />,
            },
            {
              path: "/admin/est/:courseID/add-placement-test",
              element: <AdminQuiz placement type="est" />,
            },
            {
              path: "/admin/est/:courseID/add-placement-test/:sectionNumber",
              element: <AdminQuizSection placement type="est" />,
            },
            {
              path: "/admin/est/add-course",
              element: <AdminAddEditCourse type="est" />,
            },
            {
              path: "/admin/est/edit-course/:courseID",
              element: <AdminAddEditCourse type="est" edit />,
            },
            {
              path: "/admin/est/:courseID/edit-session/:sessionID",
              element: <AdminAddEditSession edit />,
            },
            {
              path: "/admin/est/:courseID/add-session",
              element: <AdminAddEditSession />,
            },
            {
              path: "/admin/est/all-students/:courseID",
              element: <AdminViewExam type="est" addStudent />,
            },
            {
              path: "/admin/est/all-students/:courseID/add-student",
              element: <AdminAddStudentToCourse type="est" />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID",
              element: <AdminCourseSession type="est" />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/add-exam",
              element: <AdminAddEditExamQuiz isExam type="est" />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/add-homework",
              element: <AdminAddEditExamQuiz type="est" />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/edit-exam",
              element: <AdminAddEditExamQuiz isExam edit />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/edit-homework",
              element: <AdminAddEditExamQuiz edit />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/exam/:examID",
              element: <AdminQuiz exam type="est" />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/homework/:examID",
              element: <AdminQuiz type="est" />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/exam/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/homework/:examID/:sectionNumber",
              element: <AdminQuizSection />,
            },
            {
              path: "/admin/est/:courseID/session/:sessionID/:examID",
              element: <AdminViewExam />,
            },
          ],
        },

        // ACT MATERIALS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("act")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/act",
              element: <AdminCourses type="act" />,
            },
            {
              path: "/admin/act/:courseID",
              element: <AdminCourseDetails type="act" past />,
            },
            {
              path: "/admin/act/add-course",
              element: <AdminAddEditCourse type="act" />,
            },
            {
              path: "/admin/act/edit-course/:courseID",
              element: <AdminAddEditCourse type="act" edit />,
            },
            {
              path: "/admin/act/:courseID/edit-session/:sessionID",
              element: <AdminAddEditSession edit />,
            },
            {
              path: "/admin/act/all-students/:courseID",
              element: <AdminViewExam type="act" addStudent />,
            },
            {
              path: "/admin/act/:courseID/add-session",
              element: <AdminAddEditSession />,
            },
            {
              path: "/admin/act/all-students/:courseID/add-student",
              element: <AdminAddStudentToCourse type="act" />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID",
              element: <AdminCourseSession type="act" />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/add-exam",
              element: <AdminAddEditExamQuiz isExam type="act" />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/add-homework",
              element: <AdminAddEditExamQuiz type="act" />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/edit-exam",
              element: <AdminAddEditExamQuiz isExam edit />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/edit-homework",
              element: <AdminAddEditExamQuiz edit />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/exam/:examID",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/homework/:examID",
              element: <AdminQuiz type="act" />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/exam/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/homework/:examID/:sectionNumber",
              element: <AdminQuizSection />,
            },
            {
              path: "/admin/act/:courseID/session/:sessionID/:examID",
              element: <AdminViewExam />,
            },
            {
              path: "/admin/act/:courseID/add-placement-test",
              element: <AdminPlacementTest />,
            },
          ],
        },
        // sat MATRIALS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("dsat")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/sat",
              element: <AdminCourses type="sat" />,
            },
            {
              path: "/admin/sat/:courseID",
              element: <AdminCourseDetails type="sat" past />,
            },
            {
              path: "/admin/sat/add-course",
              element: <AdminAddEditCourse type="sat" />,
            },
            {
              path: "/admin/sat/edit-course/:courseID",
              element: <AdminAddEditCourse type="sat" edit />,
            },
            {
              path: "/admin/sat/:courseID/edit-session/:sessionID",
              element: <AdminAddEditSession edit />,
            },
            {
              path: "/admin/sat/all-students/:courseID",
              element: <AdminViewExam type="sat" addStudent />,
            },
            {
              path: "/admin/sat/:courseID/add-session",
              element: <AdminAddEditSession />,
            },
            {
              path: "/admin/sat/all-students/:courseID/add-student",
              element: <AdminAddStudentToCourse type="sat" />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID",
              element: <AdminCourseSession type="sat" />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/add-exam",
              element: <AdminAddEditExamQuiz isExam type="sat" />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/add-homework",
              element: <AdminAddEditExamQuiz type="sat" />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/edit-exam",
              element: <AdminAddEditExamQuiz isExam edit />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/edit-homework",
              element: <AdminAddEditExamQuiz edit />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/exam/:examID",
              element: <AdminQuiz exam type="sat" />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/homework/:examID",
              element: <AdminQuiz type="sat" />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/exam/:examID/:sectionNumber",
              element: <AdminQuizSection exam />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/homework/:examID/:sectionNumber",
              element: <AdminQuizSection />,
            },
            {
              path: "/admin/sat/:courseID/session/:sessionID/:examID",
              element: <AdminViewExam />,
            },
            {
              path: "/admin/sat/:courseID/add-placement-test",
              element: <AdminPlacementTest />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("skills")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/domains",
              element: <AdminDomains />,
            },
            {
              path: "/admin/domains/:section",
              element: <AdminSkills />,
            },

            {
              path: "/admin/domains/:section/:skillID",
              element: <AdminSkills />,
            },
            {
              path: "/admin/domains/:section/add-domain",
              element: <AdminAddEditSkills />,
            },
            {
              path: "/admin/domains/:section/:skillID/add-skill",
              element: <AdminAddEditSkills addEditSkill />,
            },
          ],
        },

        // EDIT QUESTIONS
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },

        //! exams

        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/admin/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("exam_view")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/exams/all-students/:examID",
        //       element: <AdminViewExam isExam />,
        //     },
        //     {
        //       path: "/admin/exams/all-students/:examID/model-answer",
        //       element: <AdminModalAnswer isExam />,
        //     },
        //   ],
        // },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={true}
            />
          ),
          children: [
            {
              path: "/admin/profile",
              element: <AdminProfile />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/assistants",
              element: <Assistants />,
            },
            {
              path: "/admin/assistants/add-assistant",
              element: <AddEditAssistant />,
            },
            {
              path: "/admin/assistants/edit-assistant/:assistantID",
              element: <AddEditAssistant edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/admin/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("reviews")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/reviews",
              element: <Reviews />,
            },
          ],
        },
        {
          path: "/admin/not-found",
          element: <NotFound />,
        },
      ],
    },
    //!SignIn-routes
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/students"
              : "/home/courses"
          }`}
          isAllowed={!AuthState.userData}
        />
      ),
      children: [
        //!SignIn

        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/admin/signin",
          element: <SignIn admin />,
        },
        //!Register
        {
          path: "/register",
          element: <Register />,
        },
      ],
    },

    //!NotFound

    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return (
    <main className="dar:text-light bg-white text-secondary duration-100 dark:bg-dark">
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </main>
  );
}

export default App;
